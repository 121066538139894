import { Component, OnInit } from '@angular/core';
import { Router, RoutesRecognized, } from '@angular/router';
import { ProjectService } from '../project.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  folderName: string;
  projectName: string;

  constructor(private router: Router, private projectService: ProjectService) {}

  ngOnInit() {
    this.router.events.subscribe(routerEvent => {
      if (routerEvent instanceof RoutesRecognized) {
        this.folderName = routerEvent.state.root.firstChild.params["folderName"];
        const project = this.projectService.getProjects().find(x => x.folderName == this.folderName);
        this.projectName = project ? project.name : null;
      }
    });
  }

  isRouteActive(commands: any[]): boolean {
    return this.router.isActive(this.router.createUrlTree(commands), true);
  }
}
