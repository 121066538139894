import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-project-list-item',
  templateUrl: './project-list-item.component.html',
  styleUrls: ['./project-list-item.component.css']
})
export class ProjectListItemComponent implements OnInit {

  @Input()
  folderName: string;
  @Input()
  name: string = 'Unknown';
  @Input()
  description: string = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean aliquam justo at turpis vestibulum interdum. Pellentesque rhoncus, odio vel aliquam consectetur, neque augue iaculis risus, vitae posuere lorem elit a magna.';
  @Input()
  downloadUrl: string;
  @Input()
  details: string;

  constructor() { }

  ngOnInit() {
  }

  get showDownloadButton() {
    return this.downloadUrl != "" && this.details == "";
  }

  get showDetailsButton() {
    return this.details != "";
  }

}
