import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../project.service';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css']
})
export class ProjectDetailsComponent implements OnInit {

  folderName: string;
  details: string = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean aliquam justo at turpis vestibulum interdum. Pellentesque rhoncus, odio vel aliquam consectetur, neque augue iaculis risus, vitae posuere lorem elit a magna.';
  width: number = 800;

  constructor(private route: ActivatedRoute, private projectService: ProjectService)
  {
    this.folderName = this.route.snapshot.paramMap.get('folderName');

    let project = (projectService.getProjects() as any[]).find(project => project.folderName == this.folderName);
    this.details = project.details;
    if (project.width)
    {
      this.width = project.width as number;
    }
  }

  ngOnInit() {
  }

}
