import { Injectable } from '@angular/core';
import projectsJson from './../assets/projects/projects.json';
import { Project } from './project.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor() { }

  getProjects(): Project[] {
    return projectsJson;
  }
}
